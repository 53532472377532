import {Button, Modal, Row, Col} from 'react-bootstrap';
import Slider from "react-slick";

import { baseUrl } from "../../config";

function HouseModal(props) {

  const {housesinfo} = props;
  const room = housesinfo && housesinfo.room;

  var settings = {
    customPaging: function(i) {
      return (
        <a>
          <img src={`${baseUrl}/house${i + 1}/house0${i + 1}.jpg`} />
        </a>
      );
    },
    dots: true,
    dotsClass: "slick-dots slick-thumb",
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1
  };

  return (
    <Modal
      {...props}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Header closeButton>
        <Modal.Title id="contained-modal-title-vcenter">
          {housesinfo.title}
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Row>
          <Col md={7}>
            <Slider {...settings}>
              {room.map((item, index) => (
                <div key={index}>
                  <img src={item.img} alt={housesinfo.title}  />
                </div>
              ))}
            </Slider>
          </Col>
          <Col md={5}>
            <h4>Description</h4>
            <Col md={12}>
              <p className="mt30 mb10">Price: from <span className="price">{housesinfo.price} €</span> / per night</p>
            </Col>
            <Row>
              <Col sm={6}>
                <span className="size">Room size: 35m2</span>
              </Col>
            </Row>
            <Col md={12}>
              <p className="mb30">Capacity: {housesinfo.capacity}</p>
              <p className="mb30">{housesinfo.description}</p>
            </Col>
          </Col>
        </Row>
        <Row className="mt30">
              <Col sm={4}>
                <ul className="list">
                  <li>Free Breakfast</li>
                  <li>Free WiFi</li>
                  <li>Air Conditioning</li>
                  <li>Coffee table/chairs</li>
                  <li>Private bathroom</li>
                  <li>King size bed</li>
                </ul>
              </Col>
              <Col sm={4}>
                <ul className="list">
                  <li>Towels</li>
                  <li>Shower gel/shampoo</li>
                  <li>Baby cot Free (upon request)</li>
                  <li>Fire extinguisher</li>
                  <li>Mountain view</li>
                  <li>Free Parking</li>
                </ul>
              </Col>
              <Col sm={4}>
                <ul className="list">
                  <li>Terrace</li>
                  <li>Waterfalls</li>
                  <li>Free BBQ area (outdoor)</li>
                  <li>Free wood for Fire pit</li>
                  <li>Free Outdoor/Indoor cinema</li>
                </ul>
              </Col>
            </Row>
      </Modal.Body>
      <Modal.Footer>
        <Button href="https://glamping-cy.book.page/" target="_blank" onClick={props.onHide}>Book this house</Button>
      </Modal.Footer>
    </Modal>
  );
}

export default HouseModal;
