import React from 'react';
import {Col, Container, Row} from 'react-bootstrap';


function Cancelation() {
  return (
    <div id="cancelation" className="cancelation pt50">
      <Container>
      <Row>
          <Col sm={12} className="mb50">
            <div className="section-title">Booking cancelation</div>
            <h2>Cancelation terms</h2>
            <p>We regret to inform you that <u>we are unable to provide a refund for your canceled booking.</u> Our refund policy clearly states that in the event of a cancellation, <u>no refunds</u> will be issued. This policy is in place to ensure fairness and consistency for all our customers.</p>
            <p>When you made the booking, you agreed to our terms and conditions, which include the cancellation policy. We understand that unforeseen circumstances may arise, leading to the need for cancellations. <u>We can move your booking date to another day. But it must be done 4 days before your check in date.</u></p>
            <p>We apologize for any inconvenience this may cause you, and we encourage you to review our policies before making future bookings. If you have any further questions or concerns, please don't hesitate to reach out to our customer support team, and we'll be happy to assist you in any way we can.</p>
            <p>Thank you for your understanding.</p>
          </Col>
        </Row>
      </Container>
    </div>
  )
}

export default Cancelation;
