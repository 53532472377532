import React from 'react';
import './about-us.scss';
import {Col, Container, Row} from 'react-bootstrap';

function AboutUs() {
  return (
    <div id="about" className="about-us pt50">
      <div className="clouds cloud1"></div>
      <div className="clouds cloud2"></div>
      <div className="clouds cloud3"></div>
      <div className="clouds cloud4"></div>
      <Container>
        <Row>
          <Col sm={12} className="mb50">
            <div className="section-title">About Us</div>
            <h2>Amazing Cyprus Glamping Domes</h2>
            <div className="p-sides">
              <h3 className="mb30 d-block d-sm-none">Visitors can trust that glamping “Amazing Cyprus Glamping Domes” they uncover will provide a truly unique and unforgettable travel experience.</h3>
              <div className="subtitle">The way we travel has changed. We no longer want a generic, one-size-fits-all vacation. We want to explore on our terms and immerse ourselves in local culture, and we no longer just want to simply witness nature — we want to live in it. A fusion of glamour and camping, glamping is a way to authentically experience the most awe-inspiring locales around the world.</div>

              <p className="mb40 d-none d-sm-block">Some call it luxury camping.  Some call it glamorous camping.  Either way, Amazing Cyprus Glamping Domes is dedicated to inspiring and guiding those who are seeking a different kind of trip, the kind that encourages cultural immersion, a broadening of horizons, and a deeper engagement with one’s surroundings with enjoying this sleeping under the stars. </p>

              {/*<ul className="list facilities">
                <li>One of the big designed maze in Europe</li>
                <li>Zeus  - one of the seven wonders of ancient world.</li>
                <li>Cinema on the air</li>
              </ul>*/}
            </div>
          </Col>

          <Col sm={12} className="what-we-have d-none d-sm-block">
            <Row>
              <Col md={5}>
                <Row>
                  <Col sm={6} md={12}>
                    <div className="block">
                      <div className="desc"><span>800</span> trees</div>
                      <div className="sub">and we are increasing them every month</div>
                    </div>
                  </Col>
                  <Col sm={6}>
                    <div className="block">
                      <div className="desc"><span> 2</span></div>
                      <div className="sub">outside cinema</div>
                    </div>
                  </Col>
                  <Col sm={6}>
                    <div className="block">
                      <div className="desc"><span> 7</span></div>
                      <div className="sub">Waterfalls</div>
                    </div>
                  </Col>
                </Row>
                <Row>
                  <Col sm={6}>
                    <div className="block">
                      <div className="desc"><span>6</span></div>
                      <div className="sub">unique Marble statues</div>
                    </div>
                  </Col>
                  <Col sm={6}>
                    <div className="block">
                      <div className="desc"><span>4</span></div>
                      <div className="sub">Original Domes</div>
                    </div>
                  </Col>
                </Row>
              </Col>
              <Col sm={7} className="round-img d-none d-md-block"></Col>
            </Row>
          </Col>
        </Row>
      </Container>

      {/*<div className="video-section d-none d-md-block">
        <Container>
          <Row>
            <Col sm={12}>
              <h3 className="mb50">Visitors can trust that glamping “Amazing Glamping Domes” they uncover will provide a truly unique and unforgettable travel experience.</h3>
              <div className="video-block">
                <iframe title="vimeo-player" src="https://player.vimeo.com/video/681887225?h=4f3f8bcbea" width="100%"
                        height="600" frameBorder="0" allowFullScreen></iframe>
              </div>
            </Col>
          </Row>
        </Container>
      </div>*/}
    </div>
  )
}

export default AboutUs;
