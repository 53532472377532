import React from 'react';
import {NavDropdown} from 'react-bootstrap';

function LangSwitcher() {
  return(
    <>
      <NavDropdown title="EN" id="collasible-nav-dropdown">
        <NavDropdown.Item href="#action/3.1">EN</NavDropdown.Item>
        <NavDropdown.Item href="#action/3.2">GR</NavDropdown.Item>
        <NavDropdown.Item href="#action/3.3">RU</NavDropdown.Item>
      </NavDropdown>
    </>
  )
}

export default LangSwitcher;