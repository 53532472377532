import React from 'react';
import './park.scss';
import {Button, Col, Container, Row} from 'react-bootstrap';

import img1 from "./images/img1.png";
import img2 from "./images/img2.png";
import img3 from "./images/img3.png";
import img4 from "./images/img4.png";
import img5 from "./images/img5.png";

function Park() {
  return (
    <div id="park" className="pt100">
      <div className="our-park pt50 mb50">
        <div className="clouds cloud6"></div>
        <div className="clouds cloud2"></div>
        <div className="clouds cloud3"></div>

        <Container>
          <Row>
            <Col md={6} className="mb50">
              <div className="section-title">Visit our park (Very Soon)</div>
              <h3>"Amazing World" <br />
                Sculptures and Stone Maze Park</h3>
              <p className="mb40">Join us on our journey! Walk back in time and relive what is was like to stroll past Giants taller than trees, gaze upon the hills of Mount Olympus - Home of the Gods, and rest amongst the waters of Poseidon to reunite with nature. Challenge yourself through a great labyrinth where you will be faced with secret corridors and wrong turns, braving past the Minotaur, and working your way to freedom.</p>
              <Button className="btn-in">Under construction</Button>
            </Col>
            <Col md={6} className="park-imgs">
              <img className="img1" src={img1} alt=""/>
              <img className="img2 d-none d-md-block" src={img2} alt=""/>
              <img className="img3 d-none d-md-block" src={img3} alt=""/>
              <img className="img4 d-none d-md-block" src={img4} alt=""/>
              <img className="img5 d-none d-md-block" src={img5} alt=""/>
            </Col>
          </Row>
        </Container>
      </div>
    </div>
  )
}

export default Park;
