import './App.scss';
import Header from './components/header/Header';
import AboutUs from './components/about-us/AboutUs';
import Banner from './components/banner/Banner';
import Accommodations from './components/accommodations/Accommodations';
import Food from './components/food/Food';
import Services from './components/services/Services';
import Park from './components/our-park/Park';
import Gallery from './components/gallery/Gallery';
import Booking from './components/booking/Booking';
import Reviews from './components/reviews/Reviews';
import Contacts from './components/contacts/Contacts';
import Map from './components/map/Map';
import Footer from './components/footer/Footer';

import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Drinks from './components/drinks/Drinks';
import Events from './components/events/Events';
import Cancelation from './components/cancelation/Cancelation';
import Activities from './components/activities/Activities';

function App() {
  return (
    <div className="App">
      <Header />
      <Banner />
      <Gallery />
      <AboutUs />
      <Accommodations />
      <Food />
      <Drinks />
      {/*<Activities />*/}
      <Events />
      <Services />
      <Cancelation />
      {/* <Park /> */}
      <Booking />
      {/*<Reviews />*/}
      <Contacts />
      <Map />
      <Footer />
    </div>
  );
}

export default App;
