import React from 'react';
import {Nav} from 'react-bootstrap';

import './social-media.scss';

function SocialMedia() {
  return(
    <>
      <Nav id='social-media' className="me-auto">
        <Nav.Link href="https://www.facebook.com/GlampingCyprus" target="_blank" className="fb"></Nav.Link>
        <Nav.Link href="https://www.instagram.com/glamping_domes_cy/?hl=en" target="_blank" className="insta"></Nav.Link>
      </Nav>
    </>
  )
}

export default SocialMedia;