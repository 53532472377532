import React from 'react';
import './food.scss';
import {Col, Container, Row} from 'react-bootstrap';

import FoodList from '../../data/food.json';

function Food() {
  return (
    <div id="food" className="our-food pt50">
      <Container>
      <Row>
          <Col sm={12} className="mb50">
            <div className="section-title">Our food</div>
            <h2>Try New Cuisine</h2>
            <div className="p-sides">
              <div className="subtitle">
                <span className="d-none d-sm-block">Travel is not just about visiting new places, it's also about experiencing new cultures. And one of the best ways to do that is through food.</span><br />
                Don't be afraid to try something new, because you might just discover your new favorite dish. <br />
                Step out of your comfort zone and let your taste buds go on an adventure  by trying traditional homemade food!</div>
            </div>
          </Col>

          <Col md={12} className='event-item'>
            <div className="grid">
              <ul id="hexGrid">
                {FoodList && FoodList.map((item) => {
                  return (
                    <li className="hex" key={item.id}>
                      <div className="hexIn">
                        <a className="hexLink">
                          <div className='img'
                               style={{backgroundImage: "url(" + item.image + ")"}}></div>
                          <h1 id="food-title">{item.title}</h1>
                          <p id="food-desc">{item.description}</p>
                        </a>
                      </div>
                    </li>
                  )
                })}
              </ul>
            </div>
          </Col>

          {/*<Col sm={12}>
            <Row className='food-line'>
                <Col sm={6}>
                    <h3>Uzbek Plov (Lamb and Rice Pilaf)</h3>
                    <p>Traditionally, Uzbek Plov is made with lamb meat. </p>
                    <p>Though served all over central Asia, plov is a national obsession in Uzbekistan, where people pride themselves on their skill in preparing the tasty dish.</p>
                    <p>* 15 euro per person / 5 euro for kids<br />
                       * Min. order: 5 persons <br />
                       * Free salad is included
                    </p>
                </Col>
                <Col sm={6}>
                    <img src={pilaf} alt="Uzbek Plov (Lamb and Rice Pilaf)" />
                </Col>
            </Row>
            <Row>
                <Col sm={6}>
                    <h3>Fried fresh meat with potato</h3>
                    <p>It is one of the oldest recipes found in Central Asia. </p>
                    <p>Traditionally, the meat used is mutton (lamb). The most common additions beyond this are just potatoes, onions and possibly garlic. Thus, with a bit of chopping, this dish comes together in no time!</p>
                    <p>* 15 euro per person / 5 euro for kids<br />
                       * Salad: 5 euro <br /> 
                       * Min. order: 2 persons 
                    </p>
                </Col>
                <Col sm={6}>
                    <img src={fried_meat} alt="Fried meat with potato" />
                </Col>
            </Row>
          </Col>*/}
        </Row>
      </Container>
    </div>
  )
}

export default Food;
