import React from 'react';
import './drinks.scss';
import {Col, Container, Row} from 'react-bootstrap';


function Drinks() {
  return (
    <div id="drinks" className="our-drinks pt50">
      <Container>
      <Row>
          <Col sm={12} className="mb50">
            <div className="section-title">Our rules about the Drinks</div>
            <h2>Drinks at our Glamping</h2>
            <div className="p-sides">
              <div className="subtitle">
                We have the Bar, where you can buy: wine, beer, water, coca-cola, red bull, hell, cold coffees, tea, juices and other beverages and snacks.
              </div>
              <div className="subtitle">
                If you want to bring the drinks with you: ALLOWED any kind of wine, beer, champagne.
              </div>
              <div className="subtitle">
                NOT ALLOWED*: To bring more than 1 bottle of the strong alcohols like Zivania, Vodka, Whiskey, Tequila, Gin and etc. <br /><br />
                * For the events - please contact us.
              </div>
            </div>
          </Col>
        </Row>
      </Container>
    </div>
  )
}

export default Drinks;
