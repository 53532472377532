import React from 'react';
import './contacts.scss';
import {Col, Container, Row} from 'react-bootstrap';

function Contacts() {
  return (
    <div id="contacts" className="contacts pt50 mb40">
      <Container>
        <Row>
          <Col sm={12} className="mb50">
            <div className="section-title">Our contacts</div>
          </Col>

          <Col sm={6} className="address">
            <h3 className="mb40">Amazing Cyprus Glamping Domes</h3>
            <Row>
              <Col sm={2} className="label">
                Address:
              </Col>
              <Col sm={10}>
                After the Rock of Chasampoulion, before Kidasi village, 4774, Cyprus
              </Col>
            </Row>
            <Row>
              <Col sm={2} className="label">
                Phone:
              </Col>
              <Col sm={10}>
              +357 99 069 141
              </Col>
            </Row>
            <Row>
              <Col sm={2} className="label">
                Email:
              </Col>
              <Col sm={10}>
                info@amazing-world.eu
              </Col>
            </Row>
          </Col>
          <Col sm={6}></Col>
        </Row>
      </Container>
    </div>
  )
}

export default Contacts;
