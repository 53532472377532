import React from 'react';
import './footer.scss';
import {Col, Container, Nav, Row, Form, Button} from 'react-bootstrap';

function Footer() {
  return (
    <div className="footer pb50">
      <Container>
        <Row>
          <Col sm={6} md={4}>
            <h3>Amazing World Resort</h3>
            <p className="icon phone">+357 99 069141</p>
            <p className="icon email pb50">info@amazing-world.eu</p>

            <h3>Follow Us</h3>
            <ul className="soc-media">
              <li><Nav.Link href="https://www.facebook.com/GlampingCyprus" target="_blank" className="fb"></Nav.Link></li>
              <li><Nav.Link href="https://www.instagram.com/glamping_domes_cy/?hl=en" target="_blank"  className="insta"></Nav.Link></li>
            </ul>

          </Col>
          <Col sm={6} md={3}>
            <ul className="menu">
              <li><Nav.Link href="#about">About us</Nav.Link></li>
              <li><Nav.Link href="#accommodation">Accommodation</Nav.Link></li>
              {/* <li><Nav.Link href="#park">Park</Nav.Link></li> */}
              <li><Nav.Link href="#gallery">Gallery</Nav.Link></li>
              <li><Nav.Link href="#contacts">Contacts</Nav.Link></li>
            </ul>
          </Col>
          <Col md={5}>
            <h3 className="color-white">Contact us</h3>

            <Form className="contact-us-form" action="#">
              <Row>
                <Col sm={6}>
                  <Form.Group className="mb-5" controlId="formBasicEmail">
                    <Form.Label>Your name</Form.Label>
                    <Form.Control type="text" placeholder="Name Surname" />
                  </Form.Group>
                </Col>
                <Col sm={6}>
                  <Form.Group className="mb-5" controlId="formBasicEmail">
                    <Form.Label>Your Email</Form.Label>
                    <Form.Control type="email" placeholder="Enter email" />
                    {/*<Form.Text className="text-muted">
                      We'll never share your email with anyone else.
                    </Form.Text>*/}
                  </Form.Group>
                </Col>
                <Col sm={12}>
                  <Form.Group className="mb-5" controlId="exampleForm.ControlTextarea1">
                    <Form.Label>Your message</Form.Label>
                    <Form.Control as="textarea" rows={5} />
                  </Form.Group>
                </Col>
                <Col sm={12}>
                  <Button variant="primary" type="submit">
                    Send message
                  </Button>
                </Col>
              </Row>
            </Form>
          </Col>
          <Col sm={12} className="pt50">
            <p>Privacy & Cookie Policy <br /> Copyright © 2024. «Amazing Cyprus Glamping Domes». All rights reserved.</p>
          </Col>
        </Row>
      </Container>
    </div>
  )
}

export default Footer;
