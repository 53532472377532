import React from 'react';
import './events.scss';
import {Col, Container, Row, Card} from 'react-bootstrap';

import ServicesList from "../../data/events.json";

function Events() {
  return (
    <div id="events" className="pt50">
      <Container>
        <Row>
          <Col md={12} className="mb50">
            <div className="section-title">Events</div>
          </Col>
        </Row>
        <Row>
          <Col md={4}>
            <h2>Organize events <br />in our place</h2>
          </Col>
          {ServicesList && ServicesList.map((item) => {
            return (
              <Col md={4} key={item.id} className='event-item'>
                <h4>{item.title}</h4>
                <Card.Img className="event-img" variant="top" src={item.image} />
              </Col>
            )
          })}
        </Row>
      </Container>
    </div>
  )
}

export default Events;
