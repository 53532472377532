import React from 'react';
import './gallery.scss';
import {Col, Container, Row} from 'react-bootstrap';
import Slider from "react-slick";

import slide1 from "./images/gal1.jpg";
import slide2 from "./images/gal2.jpg";
import slide3 from "./images/gal3.jpg";
import slide4 from "./images/gal4.jpg";
import slide5 from "./images/gal5.jpg";
import slide6 from "./images/gal6.jpg";
import slide7 from "./images/gal7.jpg";
import slide8 from "./images/gal8.jpg";
import slide9 from "./images/gal9.jpg";
import slide10 from "./images/gal10.jpg";
import slide11 from "./images/gal11.jpg";
import slide12 from "./images/gal12.jpg";
import slide13 from "./images/gal13.jpg";
import slide14 from "./images/gal14.jpg";
import slide15 from "./images/gal15.jpg";
import slide16 from "./images/gal16.jpg";

function Gallery() {
  var settings = {
    className: "center",
    centerMode: true,
    infinite: true,
    centerPadding: "50px",
    focusOnSelect: true,
    slidesToShow: 3,
    speed: 500,
    responsive: [
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          initialSlide: 1
        }
      }
    ]
  };

  return (
    <div id="gallery" className="pt70">
      <div className="gallery pt50 mb100">
        <Container>
          <Row>
            <Col md={12}>
              <div className="section-title">Gallery</div>
            </Col>
          </Row>
        </Container>
        <Container fluid className="gallery-wrapper">
          <Row>
            <Col md={12}>
              <Slider {...settings}>
                <div>
                  <img className="img1" src={slide1} alt=""/>
                </div>
                <div>
                  <img className="img1" src={slide2} alt=""/>
                </div>
                <div>
                  <img className="img1" src={slide3} alt=""/>
                </div>
                <div>
                  <img className="img1" src={slide4} alt=""/>
                </div>
                <div>
                  <img className="img1" src={slide5} alt=""/>
                </div>
                <div>
                  <img className="img1" src={slide6} alt=""/>
                </div>
                <div>
                  <img className="img1" src={slide7} alt=""/>
                </div>
                <div>
                  <img className="img1" src={slide8} alt=""/>
                </div>
                <div>
                  <img className="img1" src={slide9} alt=""/>
                </div>
                <div>
                  <img className="img1" src={slide10} alt=""/>
                </div>
                <div>
                  <img className="img1" src={slide11} alt=""/>
                </div>
                <div>
                  <img className="img1" src={slide12} alt=""/>
                </div>
                <div>
                  <img className="img1" src={slide13} alt=""/>
                </div>
                <div>
                  <img className="img1" src={slide14} alt=""/>
                </div>
                <div>
                  <img className="img1" src={slide15} alt=""/>
                </div>
                <div>
                  <img className="img1" src={slide16} alt=""/>
                </div>
              </Slider>
            </Col>
          </Row>
        </Container>
      </div>
    </div>
  )
}

export default Gallery;
