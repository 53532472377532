import React from 'react';
import './services.scss';
import {Col, Container, Row} from 'react-bootstrap';

import ServicesList from "../../data/services.json";

function Services() {
  return (
    <div id="services" className="services pt50">
      <div className="clouds cloud1"></div>
      <div className="clouds cloud2"></div>
      <div className="clouds cloud3"></div>
      <div className="clouds cloud4"></div>
      <div className="clouds cloud5"></div>
      <Container>
        <Row>
          <Col md={12} className="mb50">
            <div className="section-title">Services</div>
          </Col>
        </Row>
        <Row>
          <Col md={4}>
            <h2>Services <br />for our guests</h2>
          </Col>
          {ServicesList && ServicesList.map((item) => {
            return (
              <Col md={4} key={item.id}>
                <div className={`icon ${item.icon}`}></div>
                <h4>{item.title}</h4>
                <p>{item.description}</p>
              </Col>
            )
          })}
        </Row>
      </Container>
    </div>
  )
}

export default Services;
