import React from 'react';
import './banner.scss';
import {Col, Container, Row} from 'react-bootstrap';

function Banner() {
  return (
    <div className="main-banner">
      <Container>
        <Row id="banner">
          <Col sm={12}>
            <h1>Amazing Cyprus Glamping Domes</h1>
            <h4 className="d-none d-sm-block">Glamour Camping is where stunning nature meets modern luxury.</h4>
            <h4>
              <a href="#food" className='banner-btn mr15'>Our food</a>
              <a href="#drinks" className='banner-btn mr15'>Drinks</a>
              {/* <a href="#activities" className='banner-btn'>Activities</a> */}
            </h4>
          </Col>
        </Row>
        <Row>
          <Col sm={3} className='minW'>
            <a className='book-now-btn btn-green' href='https://glamping-cy.book.page/'>
                Book your Dome
                <span>Check-in: 15:00 - Check-out: 11:00 am.</span>
              </a>
              <p className='small'><a href="#cancelation">Booking cancelation terms</a></p>
          </Col>
        </Row>
      </Container>
      <div className="banner-bottom"></div>
    </div>
  )
}

export default Banner;
