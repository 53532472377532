import React from 'react';
import './map.scss';
import {Col, Container, Row} from 'react-bootstrap';

function Map() {
  return (
    <div className="map">
      <Container fluid>
        <Row className="p0">
          <Col sm={12} className="p0">
            <div className="mapouter">
              <div className="gmap_canvas">
                <iframe className="gmap_iframe" width="100%" frameBorder="0" scrolling="no" marginHeight="0"
                        marginWidth="0"
                        src="https://www.google.com/maps/d/embed?mid=1kYVsUI_hxDcdFwcBvIuY-x9rUg8DWBM&hl=en&ehbc=2E312F;output=embed"></iframe>
                </div>
            </div>
          </Col>
        </Row>
      </Container>
    </div>
  )
}

export default Map;
