import React from 'react';
import './accommodations.scss';
import {Col, Container, Row, Card, Button} from 'react-bootstrap';
import Slider from "react-slick";

import HouseModal from './HouseModal';

import HousesList from "../../data/houses.json";

function Accommodations() {
  const rooms = HousesList;
  const [room, setEachRoom] = React.useState(rooms[0]);
  const [modalShow, setModalShow] = React.useState(false);

  function handleShow(eachRoom, id) {
    setEachRoom(eachRoom);
    setModalShow(id);
  }

  var settings = {
    dots: true,
    infinite: rooms.length > 5,
    autoplay: true,
    autoplaySpeed: 4000,
    //speed: 3500,
    slidesToShow: 4,
    slidesToScroll: 1,
    initialSlide: 0,
    cssEase: "linear",
    responsive: [
      {
        breakpoint: 1400,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
          dots: true
        }
      },
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
          dots: true
        }
      },
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
          initialSlide: 2
        }
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
          initialSlide: 2
        }
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1
        }
      }
    ]
  };

  return (
    <div id="accommodation" className="accommodations pt50">
      <Container>
        <Row>
          <Col sm={12} className="mb50">
            <div className="section-title">Accommodation</div>
            <h2 className="brown">Comfortable Domes<br />сrafted with care</h2>

            <Slider {...settings}>
                { rooms.map((item, index) => {
                    return (
                      <div key={index}>
                        <Card className='house' style={{ width: '18rem' }}>
                          <div className="card-top">
                            <Card.Img variant="top" src={item.image} />
                          </div>
                          <Card.Body>
                            <Card.Title><span>{item.id} /</span> {item.title}</Card.Title>
                            <Card.Text>{item.description}</Card.Text>
                            <Card.Text>Price: from <span className="price color-white">{item.price} €</span> / night</Card.Text>
                            <Card.Text>Capacity: {item.capacity}</Card.Text>
                            <div className="details">
                              <div className="mb-3 text-center">
                                <Button className="btn-in" onClick={() => handleShow(item, item.id)}>{item.see_btn}</Button>
                              </div>
                              <div className="text-center">
                                <Button className="btn-in book-btn">
                                  <a href="https://glamping-cy.book.page/" target='_blank'>{item.book_btn}</a>
                                </Button>
                              </div>
                            </div>
                          </Card.Body>
                          <Card.Footer>
                            <Button className="btn-see-more-main">{item.see_btn}</Button>
                          </Card.Footer>
                        </Card>
                        <HouseModal
                          housesinfo={room}
                          show={+modalShow === item.id}
                          onHide={() => setModalShow(false)}
                        />
                      </div>
                    )
                  })
                }
            </Slider>
          </Col>
        </Row>
      </Container>
    </div>
  );
}
export default Accommodations;

