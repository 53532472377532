import React from 'react';
import './booking.scss';
import {Col, Container, Row} from 'react-bootstrap';

function Booking() {
  return (
    <div id="booking" className="pt70">
      <div className="booking pt50">
        <Container>
          <Row>
            <Col sm={12} className="mb30">
              <div className="section-title">Booking info</div>
              <p className="pt30 text-uppercase">Use the form below. </p>
              <p>Choose the dates and the type of accommodation that suits you. <br />
                The booking request ends with a confirmation sent to you by mail or a call from our manager.</p>

                <p><u>Self-service on all our territory</u>, but our staff is always ready to help you by request.</p>

                <p><u>Important:</u> In case you want to invite your friends, during your stay, there is a charge - <u>15 euro per person</u>, who can use all facilities (swimming pool, outdoor cinema, fire place, bbq place) together with you. And they can stay in their camping tents, if they wants.</p>
            </Col>
            <Col sm={6}>
              <p className="text-uppercase">Checking in:</p>
              <p>- Check-in 15:00 <br />
                - Check-out 11:00 am.<br /><br />
                Early check-in/check-out are available on request .</p>

                <a className='book-now-btn mb40' href='https://glamping-cy.book.page/'>Book your Dome</a>
            </Col>
            <Col sm={6} id="book-details">
              <p className="text-uppercase">Payments methods:</p>
              <p>- Cash<br />
                - Credit/Debit cards<br />
                - PayPal<br />
                - Revolut<br />
                - to BOC (Bank of Cyprus) account <br />
                - Quick Pay (BOC)</p>
            </Col>
          </Row>

          {/* <Row className="booking-section mt40">
            <Col sm={12}>
              <h3>Details of your stay</h3>
              <script async="" data-form-id="815b937ceab27bfb" src="https://secured.sirvoy.com/widget/sirvoy.js"></script>
            </Col>
          </Row> */}
        </Container>
      </div>
    </div>
  )
}

export default Booking;
