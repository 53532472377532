import React, {useState} from 'react';
import SocialMedia from './soc-media/SocialMedia';
import LangSwitcher from './lang-switcher/LangSwitcher';
import {Container, Nav, Navbar, Offcanvas} from 'react-bootstrap';
import './header.scss';

import logo from "../../images/logo.png";

function Header() {
  const [menuOpen, setMenuOpen] = useState(false)
  const toggleMenu = () => {
    setMenuOpen(!menuOpen)
  }

  const handleClose = () => setMenuOpen(false)

  return(
    <>
      {['lg'].map((expand) => (
        <Navbar key={expand} bg="light" expand={expand} className="fixed-top mb-3" variant="light">
          <Container>
            <Navbar.Brand href="#banner"><img className="logo" src={logo} alt=""/></Navbar.Brand>
            <Navbar.Toggle aria-controls={`offcanvasNavbar-expand-${expand}`}
                           onClick={toggleMenu} />
            <Navbar.Offcanvas
              id={`offcanvasNavbar-expand-${expand}`}
              aria-labelledby={`offcanvasNavbarLabel-expand-${expand}`}
              placement="start"
              restoreFocus={false}
              show={menuOpen}
              onHide={handleClose}
            >
              <Offcanvas.Header closeButton>
                <Offcanvas.Title>
                  <img className="logo" src={logo} alt=""/>
                </Offcanvas.Title>
                <LangSwitcher />
              </Offcanvas.Header>
              <Offcanvas.Body>
                <Navbar.Text>
                  <a href="tel:+35799069141" className="phone">+357 99 069141</a>
                </Navbar.Text>
                <Nav id='menu-wrapper' className="me-auto">
                  <a href='#about' className='nav-link' onClick={handleClose}>About us</a>
                  <a href='#accommodation' className='nav-link' onClick={handleClose}>Accommodation</a>
                  <a href='#services' className='nav-link' onClick={handleClose}>Services</a>
                  <a href='#events' className='nav-link' onClick={handleClose}>Events</a>
                  <a href='#gallery' className='nav-link' onClick={handleClose}>Gallery</a>
                  <a href='#booking' className='nav-link' onClick={handleClose}>Booking</a>
                  {/*<a href='#reviews' className='nav-link' onClick={toggleMenu}>Reviews</a>*/}
                  <a href='#contacts' className='nav-link' onClick={handleClose}>Contacts</a>
                </Nav>
                <Nav className="soc-and-phone">
                  <a href="tel:+35799069141" className="desk-phone">+357 99 069141</a>
                  <SocialMedia />
                  {/*<LangSwitcher />*/}
                </Nav>
              </Offcanvas.Body>
            </Navbar.Offcanvas>
          </Container>
        </Navbar>
      ))}
    </>
  )
}

export default Header;